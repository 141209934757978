@import 'src/ui/styles/vars'

.description
  display: flex
  margin-bottom: 8px
  justify-content: space-between
  font-size: 14px

.columns
  display: flex
  justify-content: space-between
  gap: 3px
  align-items: flex-end

.column
  display: flex
  flex-grow: 1
  align-items: center
  justify-content: center

  &:nth-child(1)
    height: 15px
    background: $colorNR

  &:nth-child(2)
    height: 17px
    background: $colorB

  &:nth-child(3)
    height: 21px
    background: $colorBB

  &:nth-child(4)
    height: 26px
    background: $colorBBB

  &:nth-child(5)
    height: 26px
    background: $colorBBBP

  &:nth-child(6)
    height: 31px
    background: $colorA

  &:nth-child(7)
    height: 40px
    background: $colorAA

  &:nth-child(8)
    height: 49px
    background: $colorAAA

.value
  position: absolute
  font-size: 12px
  line-height: 16px
  text-align: center
  opacity: 0.5
