@import 'src/ui/styles/vars'

.periodTitle
  height: 20px
  width: 200px
  font-size: 14px
  line-height: 20px
  border-radius: 10px
  text-align: center
  background: #F3F3F3

.ratingRow
  display: flex

.ratingAxisTick
  width: 20px
  margin-top: 10px
  border-radius: 10px
  position: relative

.ratingTitle
  transform:  translateX(-50%) translateY(-50%) rotate(-90deg)
  position: absolute
  top: 50%
  left: 50%

.suggestionsRow
  display: flex
  gap: 10px
  margin: 10px 0 0 12px

.periodAxis
  display: flex
  gap: 10px
  margin-left: 32px

.tooltip
  padding: 30px
  font-size: 14px

.tooltipH1
  margin-bottom: 10px
  font-weight: 700
  font-size: 18px
  line-height: 30px

.tooltipText
  margin-bottom: 10px
  font-weight: 400
  font-size: 14px
  line-height: 20px
  &:last-child
    margin-bottom: 0

.tooltipH2
  margin-top: 20px
  margin-bottom: 10px
  font-weight: 700
  font-size: 14px
  line-height: 20px

.tooltipBold
  font-weight: 700
