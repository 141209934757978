.wrapper
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.content
  display: flex
  position: relative
  width: 814px
  height: 488px
  flex-direction: column
  flex-wrap: wrap
  align-content: center
  justify-content: space-evenly
  background: #FFFFFF
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  border-radius: 10px

  & > *
    text-align: center

.close
  position: absolute
  top: 83px
  left: 396px
  width: 3px
  height: 3px

.title
  line-height: 50px
  font-size: 36px
  font-weight: 400

.description
  line-height: 40px
  font-size: 24px
  font-weight: 400
