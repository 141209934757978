.page
  padding: 0 0 40px 0

.helpIcon
  display: inline-block
  & svg
    margin: 0 0 -2px 5px
    font-size: 20px
    color: #BCC7CC

.tooltipContent
  padding: 13px 16px
  font-size: 14px
  line-height: 22px
  font-family: 'Open Sans'

  & td
    padding-right: 20px

.tooltipBlock
  margin-bottom: 28px

.tooltipTitle
  font-weight: 700
  margin-bottom: 2px

.tooltipText
  margin-bottom: 19px
  &:last-child
    margin-bottom: 0

.tooltipTable
  font-size: 14px

.messageCover
  display: flex
  justify-content: center
  align-items: center
  height: 300px

.messageCover_caption
  color: #1976d2
  text-align: center
  font-size: 16px
  font-weight: bold

.messageCover_error
  color: #CC6A71
  text-align: center
  font-size: 16px
  font-weight: bold

