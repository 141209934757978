.description
  display: flex
  margin-bottom: 8px
  justify-content: space-between
  font-size: 14px

.columns
  height: 49px
  position: relative

.column
  position: absolute
