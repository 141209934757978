.columns
  display: flex
  height: 76px
  justify-content: space-between
  gap: 3px
  align-items: flex-end

.column
  flex-grow: 1
  position: relative

.description
  position: absolute
  width: 100%
  top: -28px
  font-size: 14px
  text-align: center
  margin-bottom: 8px

.block
  width: 100%
  background: #D9D9D9

  .column:nth-child(1) &
    height: 8px
    opacity: 0.4
  .column:nth-child(2) &
    height: 13px
    opacity: 0.5
  .column:nth-child(3) &
    height: 15px
    opacity: 0.6
  .column:nth-child(4) &
    height: 24px
    opacity: 0.7
  .column:nth-child(5) &
    height: 32px
    opacity: 0.8
  .column:nth-child(6) &
    height: 49px
