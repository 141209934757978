.button
  display: flex
  align-items: center
  height: 40px
  padding: 0 20px
  background: rgba(8, 135, 226, 0.05)
  color: #0887E2
  border: none
  border-radius: 5px
  font-family: "Open Sans"
  font-size: 14px
  cursor: pointer

  &.isActive
    background: #0887E2
    color: #fff
