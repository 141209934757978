.messageCover_spinner
  display: inline-block
  width: 70px
  text-align: center

.messageCover_dot
  width: 10px
  height: 10px
  margin: 0 3px
  background-color: #1976d2
  border-radius: 100%
  display: inline-block
  animation: bounceDelay 1.4s infinite ease-in-out
  animation-fill-mode: both
  &:nth-child(1)
    animation-delay: -0.48s
  &:nth-child(2)
    animation-delay: -0.32s
  &:nth-child(3)
    animation-delay: -0.16s

@keyframes bounceDelay
  0%, 80%, 100%
    transform: scale(0)
  40%
    transform: scale(1)
