.header
  display: flex
  height: 80px
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)
  align-items: center
  justify-content: space-between
  font-family: 'Montserrat'

.logoContainer
  font-weight: 700

.logo
  font-size: 32px
  line-height: 39px

.logoInfo
  margin-top: -9px
  font-size: 20px
  line-height: 24px
  text-shadow: -1px -1px 0px #fff

.companyContainer
  margin-top: 14px

.companyInfo
  margin-top: -2px
  font-size: 12px
  color: #0887E2
