.page
  padding: 20px 0

.params
  display: flex
  margin-bottom: 25px

.param
  display: flex
  margin-right: 25px
  align-items: center

.paramLabel
  margin-right: 8px
  font-weight: bold
  font-size: 16px
  line-height: 22px

.paramField
  &.__input
    width: 140px

  & > div
    background: #F3F3F3
    border-radius: 5px
    height: 34px
    width: 100%
  & *
    max-height: 34px
    text-align: right
    font-size: 16px !important
    -webkit-text-fill-color: rgba(0, 0, 0, 0.6) !important
    border-color: #F3F3F3 !important

.paramDescription
  margin: 4px 0

.filters
  display: flex
  margin-bottom: 16px
  justify-content: space-between

.filterHelpIcon
  display: inline-block
  & svg
    margin: 0 0 -2px 5px
    font-size: 20px
    color: #BCC7CC

.filterTooltipContent
  padding: 13px 16px
  font-size: 14px
  line-height: 22px
  font-family: 'Open Sans'

  & td
    padding-right: 20px

.filterTooltipBlock
  margin-bottom: 28px

.filterTooltipTitle
  font-weight: 700
  margin-bottom: 2px

.filterTooltipText
  margin-bottom: 19px

.filterTooltipTable
  font-size: 14px

.messageCover
  display: flex
  justify-content: center
  align-items: center
  height: 404px

.messageCover_caption
  color: #1976d2
  text-align: center
  font-size: 16px
  font-weight: bold

.messageCover_error
  color: #CC6A71
  text-align: center
  font-size: 16px
  font-weight: bold

