.header
  display: flex
  height: 80px
  align-items: center
  justify-content: space-between

.logoContainer
  display: flex
  align-items: center

.logo
  font-size: 32px
  line-height: 39px

.logoHr
  width: 0
  height: 24px
  margin: 0 20px
  border: 0.75px solid #000000

.logoInfo
  font-weight: 400
  font-size: 24px
  line-height: 40px

.controls
  display: flex
  gap: 20px
