@import 'src/ui/styles/vars'

.container
  box-sizing: border-box
  width: 250px
  height: 350px
  padding: 15px
  position: relative
  border-radius: 12px
  background: #EFEFEF
  font-size: 12px

.title
  margin-bottom: 16px
  height: 60px

.titleName
  font-weight: 800
  font-size: 18px
  line-height: 20px
  white-space: normal

  display: -webkit-box
  margin-bottom: 10px
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2
  overflow: hidden

.titleNumber
  display: flex
  margin-bottom: 6px
  align-items: center
  gap: 10px
  font-size: 14px
  line-height: 20px

.issueNumber
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  max-width: 160px

.text
  font-size: 14px
  margin-top: 38px
  line-height: 20px

.rating
  display: flex
  align-items: center
  gap: 5px

.ratingStatus
  padding: 0 6px
  min-width: 10px
  text-align: center
  border-radius: 10px

.differenceWithDeposit
  margin: 4px 0 22px
  font-weight: 700
  font-size: 36px
  line-height: 36px

.result
  margin-bottom: 30px
  font-size: 14px

.deposite_row
  display: flex
  justify-content: space-between
  padding: 5px 0
  border-bottom: 0.75px solid rgba(0, 0, 0, 0.1)

  &:last-child
    border: none

.buttonBuy
  width: 220px
  height: 50px
  color: #000 !important
  background: #fff !important
  font-size: 18px

