@import 'src/ui/styles/vars'

.columns
  display: flex
  width: 210px
  height: 60px
  margin: 10px 0 30px 0
  justify-content: space-between
  gap: 3px
  align-items: flex-end

.column
  display: flex
  width: 50px
  flex-grow: 1
  align-items: center
  justify-content: center

.value
  font-size: 12px
  color: #000000
  opacity: 0.5
