@import 'src/ui/styles/vars'

.container
  display: flex
  margin: 10px 0 0 20px
  padding: 20px 15px
  position: relative
  border: 3px solid $colorBorder
  border-radius: 12px
  height: 450px
  box-sizing: border-box

.content
  width: 1000px

.close
  position: absolute
  top: 24px
  right: 24px
  color: #3BA3EE
  cursor: pointer

.title
  margin-bottom: 20px
  line-height: 20px
  font-weight: 700
  font-size: 14px

.description
  margin-bottom: 20px
  font-size: 16px
  line-height: 22px

.bonds
  overflow-x: scroll
  white-space: nowrap
  padding-left: 10px

  & > *
    display: inline-block
    margin-right: 10px
    vertical-align: top
    &:last-child
      margin: 0

.deposite
  width: 184px
  box-sizing: border-box
  margin-top: 40px
  border-right: 1.5px solid #96D3FF
  padding-right: 15px

.deposite_title
  font-weight: 700
  font-size: 18px
  line-height: 25px
  margin-bottom: 30px
  margin-top: 12px

.deposite_row
  display: flex
  justify-content: space-between
  padding: 5px 0
  border-bottom: 0.75px solid rgba(0, 0, 0, 0.1)

  &:last-child
    border: none

.deposite_content
  margin-top: 40px
  font-size: 14px
  line-height: 20px

