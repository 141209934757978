@import 'src/ui/styles/vars'

.container
  width: 267px
  height: 430px
  padding: 15px
  position: relative
  border-radius: 12px
  background: #EFEFEF
  font-size: 12px

.title
  margin-bottom: 16px
  height: 60px

.titleName
  font-weight: 800
  font-size: 18px
  line-height: 20px
  white-space: normal

  display: -webkit-box
  margin-bottom: 2px
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2
  overflow: hidden

.titleNumber
  margin-bottom: 6px
  font-size: 14px
  line-height: 20px

.text
  font-size: 14px

.rating
  display: flex
  align-items: center
  gap: 5px

.ratingStatus
  padding: 3px 4px
  min-width: 15px
  text-align: center
  border-radius: 40px

.differenceWithDeposit
  margin: 2px 0 24px
  font-weight: 700
  font-size: 36px
  line-height: 36px

.result
  margin-bottom: 20px
  padding: 0 4px
  font-size: 14px

.resultTitle
  font-size: 14px
  margin-bottom: 10px

.resultTitle_proposal
  opacity: 0.4

.resultField
  display: flex
  position: relative
  margin-bottom: 10px
  height: 25px
  padding: 0 10px
  align-items: center
  line-height: 16px
  & span
    margin-left: 15px
    color: rgba(0, 0, 0, 0.4)
    font-weight: 400

.resultBalloon
  position: absolute
  width: 100%
  height: 25px
  left: 0
  background: rgba(0, 0, 0, 0.6)
  border-radius: 13px
  mix-blend-mode: soft-light

.resultProgress
  position: absolute
  width: 100%
  height: 25px
  left: 0
  background: rgba(255, 255, 255, 0.75)
  border-radius: 13px

.resultDescription
  position: absolute
  & span
    margin-left: 3px

.resultAmount
  position: absolute
  left: 120px
  font-weight: 400

.table
  padding: 0 4px

.field
  height: 16px
  display: flex
  margin-bottom: 8px
  line-height: 16px
  font-size: 14px

  & > div:first-child
    display: inline-block
    width: 120px

  & > div:last-child
    margin-left: 10px
    display: inline-block
    width: 150px
    text-overflow: ellipsis
    overflow: hidden

  & span
    margin-left: 4px
    color: rgba(0, 0, 0, 0.4)

.ratingField
  overflow: visible !important

.dateDiff
  display: inline-block
  margin-left: 4px
  color: #0070C1
