$color36M: rgba(217, 217, 217)
$color24M: rgba(217, 217, 217, 0.8)
$color18M: rgba(217, 217, 217, 0.7)
$color12M: rgba(217, 217, 217, 0.6)
$color9M: rgba(217, 217, 217, 0.5)
$color6M: rgba(217, 217, 217, 0.4)

$colorAAA: #56C644
$colorAA: #83DD58
$colorA: #83DD58
$colorBBBP: #A1E97F
$colorBBB: #F5F071
$colorBB: #FFCC6A
$colorB: #FFA471
$colorNR: #FF786F

$colorBorder: #96D3FF
