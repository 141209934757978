.amount
  display: inline-flex
  align-items: center

.icon
  display: flex
  & svg
    padding-top: 1.8px
    font-size: inherit
    transform: scale(0.95)
