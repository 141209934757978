@import 'src/ui/styles/vars'

.suggestionContainer.__active
  position: relative
  &:before
    position: absolute
    bottom: -20px
    left: 95px
    height: 10px
    width: 10px
    content: ''
    border: 3px solid $colorBorder
    transform: rotate(45deg)
    background: #fff
    z-index: 1
  &:after
    position: absolute
    bottom: -24.5px
    left: 90px
    height: 12px
    width: 24px
    content: ''
    background: #fff
    z-index: 1

.suggestion
  min-height: 210px
  width: 200px
  padding: 12px
  position: relative
  font-size: 14px
  line-height: 16px
  border-radius: 12px
  border: 3px solid transparent
  background: #EFEFEF
  cursor: pointer
  box-sizing: border-box

  &.__active
    background: #fff !important
    border-color: $colorBorder

  &.__unprofitable
    opacity: 0.4

  &.__empty
    display: flex
    flex-direction: column
    flex: 1

.suggestionInfo
  margin-bottom: 12px
  font-size: 14px
  font-weight: 700

.suggestionIncome
  margin-bottom: 12px
  font-weight: 700
  font-size: 36px
  line-height: 46px
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap

.suggestionTitle
  line-height: 16px
  font-weight: bold
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 1
  overflow: hidden
  margin-bottom: 5px

.suggestionField
  display: flex
  margin-bottom: 6px
  margin-top: auto

.suggestionFieldTitle
  width: 80px

.suggestionFieldValue
  width: 80px
  margin-left: 10px
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.suggestionRating
  position: absolute
  right: 15px
  top: 12px

.isUnprofitableTooltip
  padding: 13px 16px
  font-size: 14px
  line-height: 22px
  font-family: 'Open Sans'
