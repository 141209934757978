@import 'src/ui/styles/vars'

.container
  height: 540px
  margin: 10px 0 0 22px
  padding: 20px
  position: relative
  border: 3px solid $colorBorder
  border-radius: 12px

.close
  position: absolute
  top: 24px
  right: 24px
  color: #3BA3EE
  cursor: pointer

.title
  margin-bottom: 3px
  font-weight: 800
  font-size: 26px
  line-height: 35px

.description
  margin-bottom: 20px
  font-size: 16px
  line-height: 22px

.bonds
  overflow-x: scroll
  white-space: nowrap

  & > *
    display: inline-block
    margin-right: 10px
    vertical-align: top
    &:last-child
      margin: 0
