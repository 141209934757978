@import 'src/ui/styles/vars'

.bgRatingAAA
  background: $colorAAA
.bgRatingAA
  background: $colorAA
.bgRatingA
  background: $colorA
.bgRatingBBBP
  background: $colorBBBP
.bgRatingBBB
  background: $colorBBB
.bgRatingBB
  background: $colorBB
.bgRatingB
  background: $colorB
.bgRatingNR
  background: $colorNR

.bgPeriod6M
  background: $color6M
.bgPeriod9M
  background: $color9M
.bgPeriod12M
  background: $color12M
.bgPeriod18M
  background: $color18M
.bgPeriod24M
  background: $color24M
.bgPeriod36M
  background: $color36M
