.params
  display: flex
  margin-bottom: 25px

.param
  display: flex
  margin-right: 40px
  align-items: center

.paramLabel
  margin-right: 10px
  font-weight: bold
  font-size: 18px
  line-height: 25px

.paramField
  &.__input
    width: 140px

  & > div
    background: #F3F3F3
    border-radius: 5px
    height: 34px
    width: 100%
  & *
    max-height: 34px
    text-align: right
    font-size: 16px !important
    -webkit-text-fill-color: rgba(0, 0, 0, 0.6) !important
    border-color: #F3F3F3 !important

.paramDescription
  margin: 4px 0
