@import 'src/ui/styles/vars'

.title
  display: flex
  align-items: center
  margin-bottom: 16px
  font-weight: 700
  font-size: 26px
  line-height: 35px

.periodTitle
  height: 16px
  width: 200px
  font-size: 14px
  line-height: 16px
  border-radius: 15px
  text-align: center

.ratingRow
  display: flex

.ratingAxisTick
  width: 10px
  margin-top: 10px
  border-radius: 25px

.suggestionsRow
  display: flex
  gap: 10px
  margin: 10px 0 0 12px

.periodAxis
  display: flex
  gap: 10px
  margin-left: 22px
