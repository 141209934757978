@import 'src/ui/styles/vars'

.suggestionContainer.__active
  position: relative
  &:before
    position: absolute
    bottom: -21px
    left: 95px
    height: 10px
    width: 10px
    content: ''
    border: 3px solid $colorBorder
    transform: rotate(45deg)
    background: #fff
    z-index: 1
  &:after
    position: absolute
    bottom: -25px
    left: 90px
    height: 12px
    width: 24px
    content: ''
    background: #fff
    z-index: 1

.suggestion
  height: 200px
  width: 200px
  padding: 12px
  position: relative
  font-size: 14px
  line-height: 20px
  border-radius: 10px
  border: 3px solid transparent
  background: #EFEFEF
  cursor: pointer
  box-sizing: border-box

  &.__active
    background: #fff !important
    border-color: $colorBorder

  &.__unprofitable
    opacity: 0.4

  &.__empty
    display: flex
    flex-direction: column
    flex: 1

.suggestionInfo
  margin-bottom: 12px
  font-size: 14px
  font-weight: 700

.suggestionIncome
  margin-bottom: 25px
  font-size: 18px
  line-height: 25px
  font-weight: 400

.suggestionDifferenceWithDeposite
  margin-bottom: 30px
  font-size: 36px
  line-height: 50px
  font-weight: 700

.suggestionBonds
  display: flex

.isUnprofitableTooltip
  padding: 13px 16px
  font-size: 14px
  line-height: 22px
  font-family: 'Open Sans'
