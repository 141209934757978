.filter
  width: 400px

.title
  margin-bottom: 5px
  font-weight: 700
  font-size: 18px
  line-height: 25px

.chart
  margin-bottom: 15px

.valueLabel
  position: absolute
  width: 10px
  height: 10px
  margin: -3px 0 0 -5px
  background: #bdbdbd
  border-radius: 100%

  & span
    position: absolute
    top: 14px
    font-weight: 700
    font-size: 16px
    color: #000000
    white-space: nowrap

    &.valueLabelMin
      left: 0
      &.__isNear
        left: auto
        right: 0
      &.__isClose
        left: auto
        right: 5px

    &.valueLabelMax
      right: 0
      &.__isNear
        right: auto
        left: 0
      &.__isClose
        right: auto
        left: 5px
