.titleFilter
  display: flex
  margin: 20px 0 40px 0

.title
  font-weight: 700
  font-size: 36px
  line-height: 50px

.amountInput
  width: 300px !important
  margin: 0 10px 0 20px

  & > div
    height: 50px
  & *
    max-height: 50px
    font-size: 24px !important
