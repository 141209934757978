.howItWorks
  display: flex
  height: 250px
  margin-bottom: 40px
  padding-top: 20px
  position: relative
  background: #F3F3F3
  border-radius: 10px

  &:after
    position: absolute
    top: -10px
    left: 1110px
    width: 20px
    height: 20px
    content: ''
    transform: rotate(45deg)
    background: #F3F3F3

.infoBlock
  width: 760px
  height: 250px
  border: 0 solid rgba(0, 0, 0, 0.25)
  border-right-width: 0.75px
  background-repeat: no-repeat
  background-position: right

.title
  padding: 40px 0 0 30px
  font-weight: 700
  font-size: 18px
  line-height: 25px

.bullets
  margin: 20px 0 0 30px

.bullet
  display: flex
  margin-bottom: 10px

.bulletNumber
  display: flex
  width: 20px
  height: 20px
  margin-right: 10px
  border-radius: 10px
  background: #fff
  align-items: center
  justify-content: center
  font-size: 10px

.bulletText
  font-size: 14px
  line-height: 20px

.linksBlock
  display: flex
  width: 434px
  padding: 45px 40px

.linksCol
  margin-right: 40px

  &:last-child
    margin: 0

.link
  display: block
  margin-bottom: 10px
  font-weight: 400
  font-size: 14px
  line-height: 20px
  color: #0887E2
  text-decoration: none

  &:hover
    text-decoration: underline

.close
  position: absolute
  top: 24px
  right: 24px
  cursor: pointer
